<template>
  <div id="vod-add">
    <el-form label-width="80px">
      <el-form-item label="文件名">
        <el-input v-model="vod.fileName"></el-input>
      </el-form-item>
      <el-form-item label="fileId">
        <el-input v-model="vod.fileId"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onAdd">立即创建</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { vodApi } from "@/api/vodApi";
import { alertMessage } from "@/util/alertMessageUtil.js";

export default {
  data() {
    return {
      vod: {
        fileId: "",
        fileName: "",
        createTime: 0,
        updateTime: 0,
      },
    };
  },
  mounted: function() {
    if (this.$route.query && this.$route.query.fileId) {
      const id = this.$route.query.fileId;
      vodApi.getVod(id).then((ret) => {
        if (ret.code == 0) {
          this.vod = ret.data;
        }
      });
    }
  },

  methods: {
    onAdd() {
      let ans = this.verify();
      if (!ans) {
        return;
      }
      vodApi
        .addVod(this.vod)
        .then((ret) => {
          if (ret.code === 0) {
            alertMessage("创建成功", "success");
            this.vod = ret.data;
          }
        })
        .catch((err) => {
          console.log(err);
          alertMessage("创建失败", "error");
        });
    },
    onCancel() {
      this.$router.push({ path: "/vod/list" });
    },
    verify() {
      if (!this.vod.fileId) {
        alertMessage("fileId不能为空", "warning");
        return false;
      }
      if (!this.vod.fileName) {
        alertMessage("文件名不能为空", "warning");
        return false;
      }
      return true;
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
#vod-add {
  margin: 20px;
  width: 60%;
  min-width: 600px;
}
</style>
